@import '../../../settings/Settings';

.EditorContainer {
  align-self: stretch;
  flex: 0 0 calc(100% -  #{$topNavigationBarHeight});
  height: calc(100% - #{$topNavigationBarHeight});

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  .EditorWrapper {
    align-self: stretch;
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}