$darkThemeFirstColor: #171717;
$darkThemeSecondColor: #282828;
$darkThemeThirdColor: #4c4c4c;
$darkThemeForthColor: #262c2f;

$primaryColor: #D68900;
$secondaryColor: #fead1c;

$greyOne: #dfdfdf;
$greyTwo: #fbfbfb;

$topNavigationBarHeight: 35px;
$stateBarHeight: 2px;
$sideNavigationBarCompanionWidth: 23px;
$sideNavigationBarContentWidth: 300px;
$editorBottomNavigationBarHeight: 40px;
$editorTopNavigationBarHeight: 40px;
$toolboxWidth: 50px;
