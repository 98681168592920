@import '../../../settings/Settings';

.VerticalEditorButton {
  height: $sideNavigationBarCompanionWidth;
  width: 80px;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: 55px 25px;
  margin-bottom: 60px;
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  color: white;
  font-size: 13px;

  img {
    width: 15px;
    height: 15px;
    filter: brightness(0) invert(1);
    margin-right: 5px;
    user-select: none;
  }

  &:hover {
    background-color: $secondaryColor; // fallback if new css variables are not supported by browser
    background-color: var(--leading-color);
  }

  &.active {
    background-color: $secondaryColor; // fallback if new css variables are not supported by browser
    background-color: var(--leading-color);
  }
}