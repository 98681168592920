.ImagesDropZone {
  opacity: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .DropZone {
    user-select: none;
    width: 400px;
    height: 250px;
    box-shadow: black 0 0 0 2px inset;
    border-radius: 4px;
    margin-bottom: 6px;

    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;

    > img {
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 10px;
      user-select: none;
    }

    > input {
      outline: none;
    }

    > p {
      margin-top: 2px;
      margin-bottom: 0;

      &.extraBold {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .DropZoneButtons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .TextButton {
      width: calc(50% - 3px);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      &.disabled {
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 2px inset;
        color: rgba(0, 0, 0, 0.2);
        cursor: default;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 2px inset;
          color: rgba(0, 0, 0, 0.2);
          background-color: transparent;
        }
      }
    }
  }
}