@import '../../../../settings/Settings';

.LabelsToolkit {
  align-self: stretch;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  .Header {
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    padding: 0 25px;
    color: white;
    font-size: 14px;
    user-select: none;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    .HeaderGroupWrapper {
      align-self: stretch;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .Marker {
      position: absolute;
      height: calc(100% - 4px);
      width: 5px;
      top: 2px;
      left: -5px;
      background-color: $darkThemeThirdColor;
      transition: background-color 0.3s ease-in-out, transform 0.3s;
    }

    .Ico {
      max-width: 20px;
      max-height: 20px;
      margin-right: 20px;
      filter: brightness(0) invert(1);
    }

    .Arrow {
      max-width: 12px;
      max-height: 12px;
      filter: brightness(0) invert(1);
    }

    &:hover {
      .Marker {
        transform: translate(5px);
      }
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.1);
      .Marker {
        transform: translate(5px);
        background-color: $secondaryColor; // fallback if new css variables are not supported by browser
        background-color: var(--leading-color);
      }

      .Arrow {
        transform: rotate(180deg);
      }
    }
  }

  .Content {
    align-self: stretch;
    box-sizing: border-box;
    height: 0;
    transition: height 0.3s ease-in-out;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

