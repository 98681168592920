@import '../../settings/_Settings';

.EditorView {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

  background-color: $darkThemeThirdColor;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  &.withPopup {
    filter: blur(2px) brightness(0.3);
    pointer-events: none;
  }
}