@import '../../../settings/Settings';

.SideNavigationBar {
  align-self: stretch;
  background-color: $darkThemeSecondColor;

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  &.with-context {
    .NavigationBarContentWrapper {
      background-color: $darkThemeForthColor;
    }
  }

  .CompanionBar {
    align-self: stretch;
    min-width: $sideNavigationBarCompanionWidth;
    width: $sideNavigationBarCompanionWidth;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
  }

  .NavigationBarContentWrapper {
    align-self: stretch;
    min-width: $sideNavigationBarContentWidth;
    width: $sideNavigationBarContentWidth;
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
  }

  &.left {
    flex-direction: row;
    border-right: solid 1px $darkThemeFirstColor;

    .CompanionBar {
      border-right: solid 1px $darkThemeFirstColor;

      .VerticalEditorButton {
        transform: rotate(-90deg) translateY(-2px);
      }
    }
  }

  &.right {
    flex-direction: row-reverse;
    border-left: solid 1px $darkThemeFirstColor;

    .CompanionBar {
      border-left: solid 1px $darkThemeFirstColor;

      .VerticalEditorButton {
        transform: rotate(-90deg) translateY(-1px);
      }
    }
  }

  &.closed {
    border: none;
  }
}
