@import '../../../settings/Settings';

.LoadLabelsPopupContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 30px;
  flex: 1;
  min-height: 450px;

  .Message {
    align-self: stretch;
    color: white;
    font-size: 15px;
    padding: 0 40px 30px 40px;
    border-bottom: solid 1px $darkThemeFirstColor;
  }

  .DropZone {
    user-select: none;
    align-self: stretch;
    border-radius: 4px;
    flex: 1;
    color: white;

    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    > img {
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 30px;
      filter: brightness(0) invert(1);
      user-select: none;
    }

    > input {
      outline: none;
    }

    > p {
      margin-top: 2px;
      margin-bottom: 0;

      &.extraBold {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}