@import '../../../../settings/Settings';

.DropDownMenuWrapper {
  align-self: stretch;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .DropDownMenuContent {
    z-index: 10000;
    position: absolute;
    background-color: $darkThemeSecondColor;
    width: 250px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: solid 1px $darkThemeFirstColor;
    border-left: solid 1px $darkThemeFirstColor;
    border-right: solid 1px $darkThemeFirstColor;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.75);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    .DropDownMenuContentOption {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      cursor: pointer;
      align-self: stretch;
      height: 40px;

      .Marker {
        height: 36px;
        width: 5px;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out, transform 0.3s;
      }

      img {
        max-width: 18px;
        max-height: 18px;
        margin: 0 20px;
        filter: brightness(0) invert(1);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);

        .Marker {
          background-color: $darkThemeThirdColor;
        }

        &.active {
          .Marker {
            background-color: $secondaryColor;
          }
        }
      }
    }
  }

  .DropDownMenuTab {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 10px;
    border-radius: 3px;
    align-self: stretch;
    cursor: pointer;
    font-weight: 700;
    transition: 0.3s ease-in-out;

    &:not(:last-child) {
      margin-right: 4px;
    }

    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      user-select: none;
      filter: brightness(0) invert(1);
    }

    &:hover {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 1.0);
    }
  }
}